import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  
  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  /**
   * Displays a snack bar notification.
   * @param message - The message key to be translated.
   * @param status - The type of message ('success', 'error', 'warn').
   */
  show(message: string, status: 'success' | 'error' | 'warn'): void {
    const translatedMessage = this.translate.instant(message); // Translate the message
    let panelClass: string;

    switch (status) {
      case 'success':
        panelClass = 'snackbar-success';
        break;
      case 'error':
        panelClass = 'snackbar-error';
        break;
      case 'warn':
        panelClass = 'snackbar-warn';
        break;
      default:
        panelClass = 'snackbar-default';
    }

    this.snackBar.open(translatedMessage, 'Close', {
      duration: 5000, // 5 seconds
      panelClass: [panelClass],
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }
}
